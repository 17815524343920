<template>
  <div class="myorder">
    <van-nav-bar title="我的订单" left-arrow @click-left="goBack"/>
    <van-tabs v-model:active="active" :color="'#F94840'" :title-active-color="'#F94840'" @change="onChangeTab" animated>
      <van-tab v-for="index of 3" :key="index">
        <template #title>
          <div v-if="index === 1">全部</div>
          <!--<div v-if="index === 2">待付款</div>-->
          <div v-if="index === 2">已完成</div>
          <div v-if="index === 3">已退款</div>
        </template>
      </van-tab>
    </van-tabs>
    <div class="content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          @offset="10"
        >
          <div v-for="(item, index) in list" :key="index" class="item-box">
            <div class="header">
              <span class="copy" :data-clipboard-text="item.orderNo" @click="copy">订单号：{{ item.orderNo }} <van-icon
                class-prefix="iconfont icon" name="fuzhi" size="18"/></span>
              <span>{{ item.statusDesc }}</span>
            </div>
            <div class="goods">
              <van-card
                v-for="(one, index) in item.vos"
                :key="index"
                :num="one.num"
                :price="one.price"
                :title="one.name"
                :thumb="one.cover"
              >
                <template #tags>
                  <van-tag plain type="danger">7天无理由退换</van-tag>
                </template>
              </van-card>
            </div>
            <div class="goods">
              <span>{{item.goodsDesc}}</span>
            </div>
            <div class="total">
              <span class="totalPrice">总价￥<i>{{ item.totalPrice }}</i></span>
              <span class="paymentAmount">实付款￥<i>{{ item.payPrice }}</i></span>
            </div>
            <div class="options" v-if="item.status == 5">
              <van-button plain round type="primary" size="small" color="#f08080" @click="showKefuPopup">联系客服
              </van-button>
              <van-button plain round type="primary" size="small" color="#d81e06" @click="orderPay(item.temps)">订单支付
              </van-button>
            </div>
            <div class="options" v-if="item.status == 1">
              <van-button plain round type="primary" size="small" color="#f08080" @click="showKefuPopup">联系客服
              </van-button>
              <van-button plain round type="primary" size="small" color="#1296db"
                          @click="showDeliveryPoput(item.orderNo)" v-if="item.delivery">配送信息
              </van-button>
            </div>
            <div class="options" v-if="item.status == 4">
              <van-button plain round type="primary" size="small" color="#f08080" @click="showKefuPopup">联系客服
              </van-button>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <van-popup v-model:show="showKefu" class="kefuPopup">
        <div class="kefu-tips">长按下方二维码图片，选择“打开对方的企业微信名片”</div>
        <van-image width="206px" height="206px" :src="require('@/assets/images/1d23d0377d605c0aa4cb8fd6e519ffb.png')"/>
      </van-popup>
      <van-popup v-model:show="showDelivery">
        <div class="deliveryInfo">
          <div><span>收货人</span><span>{{deliveryInfo.consignee}}</span></div>
          <div><span>联系电话</span><span>{{deliveryInfo.cneeMobile}}</span></div>
          <div><span>详细地址</span><span>{{deliveryInfo.cneeAddress}}</span></div>
          <div v-if="deliveryInfo.pullStatus>0"><span>快递公司</span><span>{{deliveryInfo.company}}</span></div>
          <div v-if="deliveryInfo.pullStatus>0"><span>快递单号</span><span>{{deliveryInfo.billNo}}</span></div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
  import {reactive, toRefs} from 'vue';
  import {showToast } from 'vant';
  import {useRouter} from 'vue-router';
  import {getOrderList, getDelivery} from '@/service/user'
  import Clipboard from 'clipboard'

  export default {
    setup() {
      const storage = localStorage.getItem("token-hyxt");
      const token = JSON.parse(storage);
      const router = useRouter();
      const state = reactive({
        active: 1,
        list: [],
        page: 1,
        loading: false,
        finished: false,
        refreshing: false,
        showKefu: false,
        showDelivery: false,
        currentOrder: -1,
        deliveryInfo: {}
      });
      const goBack = () => history.back();
      const onChangeTab = () => {
        // console.log("on change tab");
        onRefresh();
      }
      const loadData = async () => {
        let type = '';
        switch (state.active) {
          case 0:
            type = 0;
            break;
          case 1:
            type = 1;
            break;
          case 2:
            type = 4;
            break;
        }
        console.log("订单状态")
        console.log(type)
        let res = await getOrderList(token.userId, type);
        // console.log("load data list", res.data);
        state.list = res.data.data;
        if (state.list.length < 1) {
          state.finishedText = "暂无数据";
          state.finished = true; //上拉结束
        } else if (state.list.length >= 8) {   //修改位res.data.list.length < 1 适配正式环境
          state.finishedText = "没有更多了";
          state.finished = true; //上拉结束
        }
        // else {
        //   state.loading = false;
        // }
      };
      const onLoad = () => {
        // console.log("on load type:", state.active);
        if (state.refreshing) {
          state.list = [];
          state.refreshing = false;
        }
        loadData();
        state.page++;
      }
      const onRefresh = () => {
        // console.log("on refresh");
        // 方便onChangeTab调用该方法时的功能复用
        state.refreshing = true;
        // 清空列表数据
        state.finished = false;
        // 将 loading 设置为 true，表示处于加载状态
        state.loading = true;
        state.page = 1;
        onLoad();
      };
      // const goto = (orderNo) => {
      //   router.push({path: '/orderDetail', query: {id: orderNo}});
      // };
      const copy = () => {
        let clipboard = new Clipboard('.copy')
        clipboard.on('success', (e) => {
          console.log('复制成功', e)
          showToast ("已复制到剪贴板");
          // 释放内存
          clipboard.destroy()
        })
        clipboard.on('error', (e) => {
          // 不支持复制
          console.log('该浏览器不支持自动复制', e)
          // 释放内存
          clipboard.destroy()
        })
      };
      // 显示客服
      const showKefuPopup = () => {
        // console.log("show kefu")
        // state.showKefu = true;
        location.href = "https://work.weixin.qq.com/kfid/kfc1183239a0a660ddc"
      };
      const orderPay = (temps) => {
        console.log("订单支付")

        console.log(JSON.stringify(temps))
        let products = [];

        for (let i = 0; i < temps.length; i++) {
          let product = {};
          product.packId = temps[i].productPackId;
          product.amount = temps[i].amount;
          products.push(product);
        }
        router.push({path: '/pay/book', query: {goods: JSON.stringify(products)}});
      }
      // 显示送货地址
      const showDeliveryPoput = (orderNo) => {
        state.showDelivery = true;
        getDelivery(orderNo).then(res => {
          state.deliveryInfo = res.data.data;
        })
        console.log("配送信息")
        console.log(orderNo)

      }
      return {
        ...toRefs(state),
        goBack,
        onChangeTab,
        onLoad,
        onRefresh,
        copy,
        showKefuPopup,
        showDeliveryPoput,
        orderPay
      };
    }
  }
</script>

<style lang="scss">
  .myorder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .content {
      padding: 0 18px;
      flex: 1;
      overflow: scroll;
      .item-box {
        background-color: #FFFFFF;
        padding: 12px 12px;
        margin: 12px auto;
        border-radius: 8px;
        .header {
          font-size: 24px;
          color: #8a8a8a;
          line-height: 60px;
          display: flex;
          justify-content: space-between;
        }
        .goods {
          --van-card-font-size: 24px;
          .van-tag {
            // border-radius: 8px;
            padding: 8px 16px;
            font-size: 20px;
            line-height: 24px;
          }
        }
        .goods{
          font-size: 22px;
          font-weight: 500;
        }
        .total {
          line-height: 60px;
          font-size: 22px;
          font-weight: 500;
          margin-top: 12px;
          display: flex;
          justify-content: flex-end;
          span {
            padding: 8px 8px 8px 24px;
            i {
              font-size: 32px;
              // font-weight: 600;
            }
          }
          > .totalPrice {
            color: #bfbfbf;
          }
        }
        .options {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-bottom: 12px;
          button {
            margin-left: 18px;
            padding: 24px 24px;
          }
        }
      }
      .kefuPopup {
        width: 370px;
        height: 440px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .kefu-tips {
          color: #F76968;
          font-size: 24px;
          padding-top: 8px;
          padding-left: 6px;
          padding-right: 6px;
          line-height: 34px;
          text-align: center;
        }
      }
      .deliveryInfo {
        padding: 24px;
        width: 600px;
        font-size: 24px;
        line-height: 48px;
        div {
          display: flex;
          span:first-child {
            width: 120px;
            font-weight: 500;
          }
          span:last-child {
            flex: 1;
          }
        }
      }
    }
  }
</style>
